import React, { Component } from "react";
import "./pageTelMob.css";
import ReactGA from 'react-ga';

ReactGA.initialize('G-NJ0F6YH0PD');

export default class PageTelMobile extends Component {
  componentDidMount() {
    // Track page view
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Track scroll depth
    window.addEventListener('scroll', this.trackScrollDepth);

    // Add click event listeners for form fields
    const fields = document.querySelectorAll('.field input');
    fields.forEach(field => {
      field.addEventListener('click', () => this.handleFieldClick(field.id));
    });

    // Add click event listeners for buttons
    const buttons = document.querySelectorAll('button');
    buttons.forEach(button => {
      button.addEventListener('click', () => this.handleButtonClick(button.innerText));
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackScrollDepth);

    // Clean up event listeners for fields and buttons
    const fields = document.querySelectorAll('.field input');
    fields.forEach(field => {
      field.removeEventListener('click', this.handleFieldClick);
    });

    const buttons = document.querySelectorAll('button');
    buttons.forEach(button => {
      button.removeEventListener('click', this.handleButtonClick);
    });
  }

  trackScrollDepth = () => {
    const scrollDepth = Math.round((window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100);
    ReactGA.event({
      category: 'Scroll',
      action: 'Scroll Depth',
      label: window.location.pathname,
      value: scrollDepth
    });
  };

  handleFieldClick = (fieldId) => {
    ReactGA.event({
      category: 'Form Field',
      action: 'Click',
      label: fieldId
    });
  };

  handleButtonClick = (buttonText) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: buttonText
    });
  };

  render() {
    return (
      <div className="MobilePWrapper">
        <div className="MobileS1">
          <div className="MobileSPhotoWrapper">
            <img className="MobileP1" src="/Images/MobileOver.png" />
          </div>

          <div className="MobileSTexte">
            <a className="MobileTitre">Les avantages de la téléphonie mobile avec WINTECH</a><br/><br/>
            <a className="MobileTexte">Nous vous proposons une offre-sur-mesure. En complément de ses forfaits, WINTECH propose à ses clients de choisir parmi une liste de smartphones directement disponible en ligne. En option, WINTECH propose également un service après vente (SAV) pour l’ensemble des téléphones portables professionnel d’une entreprise.<br/><br/><br/>
              WINTECH vous offre la téléphonie haute définition, le tout illimité, souple et adaptée à votre organisation. Profitez d’une technologie avancée sans installation de matériels supplémentaires, sans compression de voix, garantissant la sécurité.</a>
          </div>
        </div>

        <div className="MobilelS2">
          <div className="Mobile1 MobileDisplay">
          <img className="MobileForfaitI1" src="/Images/Forfait1.png"/>
            <a className="MobileDisplayP">Forfait Silver</a>
            <a className="MobileDisplayP2 D2Color1">15Go</a> 
            <a className="MobileDisplayT">
              Appels/SMS/MMS illimitées en France<br/><br/><br/><br/>
              Appels/SMS illimités depuis l’Europe<br/><br/><br/><br/>
              Téléphone à un tarif avantageux
            </a>
          </div>
          <div className="Mobile2 MobileDisplay2">
          <img className="MobileForfaitI2" src="/Images/Forfait2.png"/>
            <a className="MobileDisplayP">Forfait Gold</a>
            <a className="MobileDisplayP3 D2Color2">50Go</a> 
            <a className="MobileDisplayT">
              Appels/SMS/MMS illimitées en France<br/><br/><br/><br/>
              Appels/SMS illimités depuis l’Europe<br/><br/><br/><br/>
              Internet mobile 20 GO en France et Europe<br/><br/><br/><br/>
              Smartphone à un tarif avantageux
            </a>
          </div>
          <div className="Mobile3 MobileDisplay3">
          <img className="MobileForfaitI3" src="/Images/Forfait3.png"/>
            <a className="MobileDisplayP">Forfait Diamant</a>
            <a className="MobileDisplayP4 D2Color3">150Go</a>
            <a className="MobileDisplayT">
              Appels/SMS/MMS illimitées en France<br/><br/><br/><br/>
              Appels/SMS illimités depuis l’Europe<br/><br/><br/><br/>
              Internet mobile 150 GO en France et Europe<br/><br/><br/><br/>
              Smartphone à un tarif avantageux
            </a>
          </div>
        </div>

        <img className="MobileBG" src="/Images/Ellipse19.png"/>
        
        
        
      </div>
    );
  }
}
