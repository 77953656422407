import React, { Component } from "react";
import "./affichageR.css";

export default class AffichageR extends Component {
  render() {
    return (
      <div className="WebPWrapper">
        <div className="TitreMainWeb">
          Transformez votre espace en une expérience immersive avec nos
          affichages dynamiques
        </div>

        <div className="WebS1">
          <div className="WebSTexte">
            <a className="WebTitre">
              Les avantages de notre affichage dynamique
            </a>
            <br />
            <br />
            <a className="WebTexte">
              L’affichage dynamique est un outil de communication professionnel
              interactif permettant, via un support numérique, la diffusion de
              contenus (images, vidéos) auprès d’une audience. Cette solution,
              globalement adoptée pour moderniser l’image d’une entreprise et
              permettre la diffusion de contenus multimédias, peut être adaptée
              à de multiples supports de communication interne ou externe :
              écrans vitrine, écrans tactiles, écrans extérieurs, murs d’images,
              panneaux led… L’affichage dynamique permet la diffusion de contenu
              et d’informations en temps réel ce qui implique également une
              gestion de cette diffusion via une plateforme ou un logiciel. Ces
              outils vous permettent également de créer du contenu (animé ou
              statique) qu’il sera ensuite possible de diffuser sur vos
              différents réseaux d’affichage afin de captiver et d’engager votre
              audience.
            </a>
          </div>

          <div className="WebSPhotoWrapper">
            <img className="WebP1 WebbP1" src="/Images/DisplayPage2.jpg" />
            <img className="WebP2 WebbP2" src="/Images/DisplayPage1.jpg" />
          </div>
        </div>

        <div className="WeblS2">
          <div className="Web2 WebDisplay2">
            <img className="WebI2" src="/Images/aff1.png" />{" "}
            <b className="TitreSubWeb">
              {" "}
              Plongez dans l'immersion totale avec nos affichages dynamiques{" "}
            </b>
            <a className="WebDisplayT">
              -Interaction en temps réel
              <br />
              <br />
              -Contenus captivants et engageants
              <br />
              <br />
              -Adaptabilité à tout support
              <br />
              <br />
            </a>
          </div>
          <div className="Web3 WebDisplay3">
            <img className="WebI3" src="/Images/aff2.png" />{" "}
            <b className="TitreSubWeb">
              Optimisez votre communication avec nos solutions d'affichage
              dynamique
            </b>
            <a className="WebDisplayT">
              -Modernisation de l’image de votre entreprise
              <br />
              <br />
              -Gestion simplifiée et centralisée
              <br />
              <br />
              -Engagement accru de votre audience
              <br />
              <br />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
