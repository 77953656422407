import React, { Component } from "react";
import "./firstElement.css";

class FirstElement extends Component {
  constructor(props) {
    super(props);
    this.scrollToElement = this.scrollToElement.bind(this); // Bind scrollToElement to the component instance (J'oubli toujours)
  }

  scrollToElement(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="wavy">
          <div className="El1">
            <span className="MSize" style={{ "--i": 2 }}>
              W
            </span>
            <span className="MSize" style={{ "--i": 3 }}>
              i
            </span>
            <span className="MSize" style={{ "--i": 4 }}>
              n
            </span>
            <span className="MSize" style={{ "--i": 5 }}>
              t
            </span>
            <span className="MSize" style={{ "--i": 6 }}>
              e
            </span>
            <span className="MSize" style={{ "--i": 7 }}>
              c
            </span>
            <span className="MSize" style={{ "--i": 8 }}>
              h
            </span>
            <span className="MSize" style={{ "--i": 9, marginLeft: "15px" }}>
              L
            </span>
            <span className="MSize" style={{ "--i": 10 }}>
              a
            </span>
            <span className="MSize" style={{ "--i": 11, marginLeft: "15px" }}>
              S
            </span>
            <span className="MSize" style={{ "--i": 12 }}>
              o
            </span>
            <span className="MSize" style={{ "--i": 13 }}>
              l
            </span>
            <span className="MSize" style={{ "--i": 14 }}>
              u
            </span>
            <span className="MSize" style={{ "--i": 15 }}>
              t
            </span>
            <span className="MSize" style={{ "--i": 16 }}>
              i
            </span>
            <span className="MSize" style={{ "--i": 17 }}>
              o
            </span>
            <span className="MSize" style={{ "--i": 18 }}>
              n
            </span>
          </div>
          <div className="El2">
            <a className="sub_slogan">
              Répondre à tous vos besoins en Télécom, Informatique et
              Bureautique
            </a>
          </div>
          <div className="PrestationsBtn">
            <button className="button-29" role="button">
              Nos Prestations
            </button>
          </div>
        </div>
        <div className="photo-container-wrap">
          <div className="photo-container"></div>
          <div className="circle red"></div>
          <div className="circle blue"></div>
          <div className="circle orange"></div>
        </div>
      </div>
    );
  }
}

export default FirstElement;
