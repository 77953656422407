import React, { Component } from "react";
import "./InternetR.css";

export default class InternetR extends Component {
  render() {
    return (
      <div className="InfoPWrapper">
        <div className="TitreMainInfo">
          Vivez la rapidité, connectez-vous à l'essentiel avec notre Internet
          fibre. Plus rapide, plus humain, plus connecté
        </div>

        <div className="InfoS1">
          <div className="InfoSPhotoWrapper">
            <img className="InfoP1" src="/Images/PIntenet2.jpg" />
            <img className="InfoP2" src="/Images/PIntenet1.jpg" />
          </div>

          <div className="InfoSTexte">
            <a className="InfoTitre">
              Les avantages du contrat de la prestation fibre avec WINTECH
            </a>
            <br />
            <br />
            <a className="InfoTexte">
              L’objectif est de protéger le réseau et de se prémunir contre tout
              type de risques pouvant dégrader ses performances. Pour faire face
              à ces nouvelles menaces, Réseaux Bureautique met en œuvre de
              nombreux moyens techniques pour assurer une sécurité du système
              d’information.
              <br />
              <br />
              La fibre optique est une nouvelle technologie, différente du fil
              de cuivre du téléphone ou de l’ADSL, et permettant de transmettre
              des données à des débits de 10 à 100 fois supérieurs, sans perte
              de données et sur de grandes distances. WINTECH propose une fibre
              entreprise dédiée, permettant à ses clients de combiner Très Haut
              Débit et qualité de service.
            </a>
          </div>
        </div>

        <div className="InfolS2">
          <div className="Info1 InfoDisplay">
            <img className="InfoI1" src="/Images/Speed1.png" />{" "}
            <b className="TitreSubWeb">Vitesse de Connexion Inégalée</b>
            <a className="InfoDisplayT">
              - Téléchargements ultra-rapides
              <br />- Streaming vidéo sans interruption
              <br />- Navigation fluide et instantanée
            </a>
          </div>
          <div className="Info2 InfoDisplay2">
            <img className="InfoI2" src="/Images/Speed2.png" />{" "}
            <b className="TitreSubWeb">Stabilité de Connexion Exemplaire </b>
            <a className="InfoDisplayT">
              - Pas de fluctuations de vitesse
              <br />- Connexion stable même à grande distance
            </a>
          </div>
          <div className="Info3 InfoDisplay3">
            <img className="InfoI3" src="/Images/Speed3.png" />{" "}
            <b className="TitreSubWeb">
              Capacité à Répondre aux Besoins Futurs
            </b>
            <a className="InfoDisplayT">
              - nombreux appareils connectés
              <br />
              - optimisation à long terme
              <br />- Logiciels anti- (antivirus, malveillants, spam, espion ) 
            </a>
          </div>
        </div>
      </div>
    );
  }
}
