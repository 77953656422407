import React, { Component } from "react";
import "./thirdElement.css";

export default class ThirdElement extends Component {
  render() {
    return (
      <div className="statistics-wrapper">
      <h1>Les chiffres clés de WinTech</h1>
      <h2>Les données révélatrices de notre performance</h2>
      <div className="statistics">
        <div>
          <h3>+10 années d'experience</h3>
          
        </div>
        <div>
          <h3>Couverture natonale</h3>
          
        </div>
        <div>
          <h3>Clients</h3>
          <p>+300</p>
        </div>
      </div>
    </div>
  );
}
}
