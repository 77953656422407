import React, { Component } from "react";
import "./pageInfromatique.css";

export default class PageInformatique extends Component {
  render() {
    return (
      <div className="InfoPWrapper">

        <div className="TitreMainInfo">Choisissez le contrat de prestation informatique de WINTECH pour une protection proactive et efficace de votre réseau contre les menaces actuelles</div>


        <div className="InfoS1">



        <div className="InfoSPhotoWrapper">
            
          <img className="InfoP1" src="/Images/InfoOver.png" />
          <img className="InfoP2" src="/Images/InfoUnder.png" />
          </div>

          <div className="InfoSTexte">
            <a className="InfoTitre">Les avantages du contrat de la prestation informatique avec WINTECH</a><br/><br/>
            <a className="InfoTexte">L’objectif est de protéger le réseau et de se prémunir contre tout type de risques pouvant dégrader ses performances. Pour faire face à ces nouvelles menaces, Réseaux Bureautique met en œuvre de nombreux moyens techniques pour assurer une sécurité du système d’information.

<br/><br/>Il convient de choisir les moyens nécessaires, suffisants, et justes.</a>
          </div>

          

        </div>




        <div className="InfolS2">
          <div className="Info1 InfoDisplay"><img className="InfoI1" src="/Images/InfoI1.png" /> <b className="TitreSubWeb">Surveillance du réseau</b>
          <a className="InfoDisplayT">
          - Sniffer<br/>
          - Détection d’intrusion</a></div>
          <div className="Info2 InfoDisplay2"><img className="InfoI2" src="/Images/InfoI2.png" /> <b className="TitreSubWeb"> Sécurité applicative </b>
          <a className="InfoDisplayT" >
          - Séparation des privilèges<br/>
          - Audit de code</a></div>
          <div className="Info3 InfoDisplay3"><img className="InfoI3" src="/Images/InfoI3.png" /> <b className="TitreSubWeb">Emploi de technologies ad hoc</b>
          <a className="InfoDisplayT">
          - Pare-feu<br/>
          - UTM<br/>
          - Logiciels anti- (antivirus, malveillants, spam, espion ) </a></div>
        </div>


        

        
       
      </div>
    );
  }
}
