import "./App.css";
import Header from "./Header/header";
import FirstElement from "./Page d'accueil/firstElement";
import SecondElement from "./Page d'accueil/secondElement";
import Footer from "./Footer/footer";
import ThirdElement from "./Page d'accueil/thirdElement";
import PageAffichageDynamique from "./Pages d'affichage/affichageDynamique";
import PageInternet from "./Pages d'affichage/internet";
import FormulaireEtude from "./Page lancer votre etude/formulaireEtude";
import NousPresenter from "./NousPresenter/nousPresenter";
import Contact from "./NousContacter/contact";
import PageCameras from "./Pages d'affichage/cameras";
import PageImprimante from "./Pages d'affichage/imprimante";
import PageTelFix from "./Pages d'affichage/pageTelFix";
import PageTelMobile from "./Pages d'affichage/pageTelMob";
import PageInformatique from "./Pages d'affichage/pageInfromatique";
import InternetR from "./Pages d'affichage/InternetR";
import { Routes, Route } from "react-router-dom";
import PageSiteWeb from "./Pages d'affichage/pagesiteweb";
import AffichageR from "./Pages d'affichage/affichageR";

import ReactGA from "react-ga";
ReactGA.initialize("G-NJ0F6YH0PD");

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <FirstElement />
              <SecondElement />
              <ThirdElement />
            </>
          }
        />
        <Route path="/PageAffichage" element={<AffichageR />} />
        <Route path="/PageInternet" element={<InternetR />} />
        <Route path="/FormulaireEtude" element={<FormulaireEtude />} />
        <Route path="/NousPresenter" element={<NousPresenter />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Cameras" element={<PageCameras />} />
        <Route path="/Imprimante" element={<PageImprimante />} />
        <Route path="/Fixe" element={<PageTelFix />} />
        <Route path="/Mobile" element={<PageTelMobile />} />
        <Route path="/Informatique" element={<PageInformatique />} />
        <Route path="/Web" element={<PageSiteWeb />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
