import React, { Component } from "react";
import "./secondElement.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default class SecondElement extends Component {
  componentDidMount() {
    this.initializeSlider();
    window.addEventListener('resize', this.initializeSlider);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.initializeSlider);
  }

  initializeSlider = () => {
    let pos = 0; // current position
    const sliderWrap = document.getElementById('slider-wrap');
    const slider = sliderWrap.querySelector('ul#slider');
    const slides = slider.querySelectorAll('li');
    const totalSlides = slides.length;
    const sliderWidth = sliderWrap.offsetWidth;

    // Set the width of each slide to match the slider wrap width
    slides.forEach(slide => {
      slide.style.width = `${sliderWidth}px`;
    });

    // Set the width of the slider
    slider.style.width = `${sliderWidth * totalSlides}px`;

    // Align slide content
    slides.forEach(slide => {
      const content = slide.querySelector('.slide-content');
      if (content) {
        content.style.left = `${(sliderWidth - content.offsetWidth) / 2}px`;
      }
    });

    // Clear existing pagination and build new ones
    const paginationWrap = document.getElementById('pagination-wrap').querySelector('ul');
    paginationWrap.innerHTML = '';
    slides.forEach((_, index) => {
      const li = document.createElement('li');
      li.addEventListener('click', () => {
        pos = index;
        updateSlider();
      });
      paginationWrap.appendChild(li);
    });

    // Event listeners for next and previous buttons
    document.getElementById('next').addEventListener('click', slideRight);
    document.getElementById('previous').addEventListener('click', slideLeft);

    // Automatic slider interval
    let autoSlider = setInterval(slideRight, 3000);

    // Hover handling to pause autoSlider
    sliderWrap.addEventListener('mouseenter', function () {
      this.classList.add('active');
      clearInterval(autoSlider);
    });

    sliderWrap.addEventListener('mouseleave', function () {
      this.classList.remove('active');
      autoSlider = setInterval(slideRight, 3000);
    });

    function slideLeft() {
      pos--;
      if (pos < 0) {
        pos = totalSlides - 1;
      }
      updateSlider();
    }

    function slideRight() {
      pos++;
      if (pos === totalSlides) {
        pos = 0;
      }
      updateSlider();
    }

    function updateSlider() {
      slider.style.left = -(sliderWidth * pos) + 'px';
      pagination();
    }

    function pagination() {
      paginationWrap.querySelectorAll('li').forEach((element, index) => {
        element.classList.remove('active');
        if (index === pos) {
          element.classList.add('active');
        }
      });
    }
  };

  render() {
    return (
      <div className="mainWrapper">
        <div id="MainTitle" className="mainTitle">Nos Solutions</div>
        <div className="secondTitle">BUREAUTIQUE , INFORMATIQUE , TÉLÉCOM</div>
        <div id="wrapper">
          <div id="slider-wrap">
            <ul id="slider">
              <li className="slidestyle1">
                <div className="photoCard">
                  <img src={process.env.PUBLIC_URL + '/Images/PhoneImage.png'} alt="Phoneimage" className="PhoneImage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Téléphone Fixe</div>
                  <div className="textContent">Une étude comparative de vos coûts de communications Solutions fixe ip <br /> Maintenance sur site & Hotline <br />Partenaire multi-opérateurs</div>
                  <a href="/Fixe" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
              <li className="slidestyle2">
                <div className="photoCard">
                  <img src={process.env.PUBLIC_URL + '/Images/Mobile.png'} alt="Phoneimage" className="MobileImage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Téléphone Mobiile</div>
                  <div className="textContent">Solutions mobiles Voix/Data Forfait illimités Internet, Mail, Sms, Mms 4G, 4G+,5G <br />Smartphone dernière génération</div>
                  <a href="/Mobile" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
              <li className="slidestyle2">
                <div className="photoCard">
                  <img src={process.env.PUBLIC_URL + '/Images/Printer.png'} alt="Phoneimage" className="PrinterImage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Solutions d'impression</div>
                  <div className="textContent">Solutions N&B et couleurs<br /> Maintenance et installation sur site <br />Commande à distance des toners <br />Facturation coût à la page <br />Audit de vos systèmes d’impression</div>
                  <a href="/Imprimante" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
              <li className="slidestyle2">
                <div className="photoCard">
                  <img className="photoInternet" src={process.env.PUBLIC_URL + '/Images/Internet.png'} alt="Phoneimage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Internet</div>
                  <div className="textContent">Fibre ( selon égibilité ) VDSL ADSL <br /> Installations de borne Wifi</div>
                  <a href="/PageInternet" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
              <li className="slidestyle2">
                <div className="photoCard">
                  <img src={process.env.PUBLIC_URL + '/Images/Informatique.png'} alt="Phoneimage" className="informatiqueImage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Informatique</div>
                  <div className="textContent">Hotline utilisateurs <br />Télémaintenance <br />Sauvegarde Mise à jour d’antivirus <br /> Création de site interne</div>
                  <a href="/Informatique" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
              <li className="slidestyle2">
                <div className="photoCard">
                  <img src={process.env.PUBLIC_URL + '/Images/Wifi.png'} alt="Phoneimage" className="wifiImage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Solutions WIFI</div>
                  <div className="textContent">Mise en place de campagne <br /> Emailing <br /> Constitution de base de données client (Grace à la wifi )</div>
                  <a href="/Web" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
              <li className="slidestyle2">
                <div className="photoCard">
                  <img src={process.env.PUBLIC_URL + '/Images/Camera.png'} alt="Phoneimage" className="cameraImage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Caméra de surveillance</div>
                  <div className="textContent">Entrepôts - Entreprises - Commerces - Magasins</div>
                  <a href="/Cameras" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
              <li className="slidestyle2">
                <div className="photoCard">
                  <img src={process.env.PUBLIC_URL + '/Images/Display.png'} alt="Phoneimage" className="displayImage" />
                </div>
                <div className="contentWrapper">
                  <div className="titleContent">Affichage dynamique</div>
                  <div className="textContent">Solutions d’affichage dynamique <br />Simples Performantes <br /> Communication visuel</div>
                  <a href="/PageAffichage" className="buttonContent">Pour en savoir plus</a>
                </div>
              </li>
            </ul>
            {/* controls */}
            <div className="btns" id="next"><FontAwesomeIcon icon={faArrowRight} /></div>
            <div className="btns" id="previous"><FontAwesomeIcon icon={faArrowLeft} /></div>
            <div id="counter"></div>
            <div id="pagination-wrap">
              <ul></ul>
            </div>
            {/* controls */}
          </div>
        </div>
      </div>
    );
  }
}
