import React, { Component } from "react";
import "./nousPresenter.css";

export default class NousPresenter extends Component {
  render() {
    const youtubeURL =
      "https://www.youtube.com/watch?v=u40fbd0K1cQ&ab_channel=UnknownCraze1";
    const urlParams = new URLSearchParams(new URL(youtubeURL).search);
    const youtubeID = urlParams.get("v");

    return (
      <div className="aboutUsWrapper">
        <div className="AboutUsBacground"> </div>

        <div className="Description">
          <div className="AboutUsTitre">Wintech</div>
          <div className="AboutUsSousTitre"></div>
          <div className="AboutUsTexte">
            {" "}
            Dans l'océan des affaires, une étoile brille,
            <br />
            Wintech, l'entreprise aux mille merveilles.
            <br />
            Sa force, telle une montagne impassible,
            <br />
            Domine les défis, sans la moindre faille.
            <br />
            <br />
            Ses innovations, comme des éclairs dans la nuit,
            <br />
            Illuminent l'avenir, apportant clarté et réussite.
            <br />
            Dans chaque projet, une perfection infinie,
            <br />
            Wintech excelle, tel un symbole de fierté.
            <br />
            <br />
            Ses équipes, unies dans un même élan,
            <br />
            Travaillent avec passion, main dans la main.
            <br />
            Tissant des liens solides, unissant les talents,
            <br />
            Wintech avance, imperturbable et triomphant.
            <br />
            <br />
            Dans le monde des affaires, elle est reine,
            <br />
            Une forteresse d'excellence, sans peine.
            <br />
            Wintech, symbole de force et de perfection,
            <br />
            Écrit son histoire, avec grandeur et passion.
            <br />
            Que son nom résonne, tel un chant de victoire,
            <br />
            Dans les cœurs de ceux qui portent son histoire.
            <br />
            Wintech, entreprise forte et parfaite,
            <br />
            Guide nos pas vers un avenir sans défaite.
          </div>
        </div>

        <div className="videoWrapper">
          <iframe
            className="video"
            title="Youtube player"
            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
            src={`https://youtube.com/embed/${youtubeID}?autoplay=1`}
          />
        </div>
      </div>
    );
  }
}
