import React, { Component } from "react";
import "./cameras.css";

export default class PageCameras extends Component {
  render() {
    return (
      <div className="PCWrapper">
        <div className="TitreMainInfo">
          Capturez chaque instant avec clarté - Choisissez nos caméras de
          qualité
        </div>

        <div className="Flex-Container">
          <div className="Flex-Row">
            <div className="Box Box1">
              <a className="B1Titre">
                Pourquoi choisir nos offres de caméra de surveillance?
              </a>
              <br />
              <br />
              <a>
                Un des gros avantages des systèmes de vidéosurveillance à
                distance via internet est qu’ils nécessitent peu d’éléments et
                s’installent de manière simple avec la possibilité d’utiliser
                des caméras IP Wi-Fi sans fil très discrètes et faciles à
                installer. <br />
                <br />
                La caméra IP Wi-Fi offre une liaison sans fil avec le réseau
                internet du domicile ou du local professionnel. Pratique, parmi
                les différents types de caméras de surveillance proposés aux
                consommateurs, la caméra IP Wi-Fi connaît un succès grandissant.{" "}
                <br />
                <br />
                La particularité de ce modèle de caméra est de se connecter en
                Wi-Fi au réseau pour partager ses images et enregistrements. La
                caméra communique donc de manière sans fil avec le modem routeur
                ou la box opérateur comme n’importe quel équipement
                informatique, ce qui permet à l’utilisateur de consulter des
                images depuis son téléphone ou sa tablette. <br />
                <br />
                À noter qu’il est possible d’ajouter à tout moment des caméras
                de surveillance supplémentaires à votre système de
                vidéosurveillance, de changer de place une caméra de sécurité
                pour surveiller un autre endroit, ou encore de vous équiper de
                caméras de surveillance extérieures si vous avez un jardin ou
                une terrasse à protéger
                <br />
                <br />
              </a>
            </div>
            <div className="Box Box2"></div>
          </div>
          <div className="Flex-Row">
            <div className="Box Box3">
              <div className="B3Titre">
                Protégez-vous efficacement : investissez dans des caméras de
                surveillance pour une sécurité sans compromis
              </div>
              <img src="/Images/Camera2.png" className="B3Image"></img>
            </div>
            <div className="Box Box4">
              <a className="B4Titre">
                La caméra de surveillance de façon générale
              </a>

              <a className="B4Texte">
                La caméra de surveillance est l’élément d’observation de
                référence dans un système de vidéosurveillance. Elle permet de
                collecter des images qui sont transmises à une centrale de
                traitement et de sauvegarde. Son installation est réalisée dans
                le but de prévenir ou de constater les actes criminels ou toute
                sorte de nuisance.
                <br />
                Pour mettre en place une caméra à domicile vous devez vous
                assurer qu’il s’agit de la meilleure option dont vous disposez
                pour assurer votre sécurité. <br />
                Cela dit, le traitement des images récupérées devra être
                proportionnel. Autrement dit, à la quête de votre sécurité, vous
                ne devez pas empiéter sur la vie privée des personnes.
              </a>

              <button className="NousContacterButtonCamera">
                Nous Contacter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
