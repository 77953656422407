import React, { Component } from "react";
import "./pagesiteweb.css";

export default class PageSiteWeb extends Component {
  render() {
    return (
        <div className="WebPWrapper">

        <div className="TitreMainWeb">Votre site web sur mesure, conçu pour exceller. Propulsez votre entreprise en ligne dès maintenant !</div>


        <div className="WebS1">



        

          <div className="WebSTexte">
            <a className="WebTitre">Les avantages du contrat de la prestation informatique avec WINTECH</a><br/><br/>
            <a className="WebTexte">Décider de créer votre site web avec WinTexh, c'est choisir la rapidité et la qualité sans compromis. Nous nous engageons à fournir des solutions de pointe en matière de conception et de développement de sites internet, adaptées à vos besoins spécifiques.<br/><br/>

Avec notre équipe d'experts dévoués, vous bénéficiez non seulement d'une livraison rapide, mais aussi d'un travail de haute qualité, garantissant une présence en ligne qui se démarque.<br/><br/>

En plus de notre expertise technique, nous offrons un service personnalisé, collaboratif et attentif à chaque étape du processus. Optez pour WinTexh et donnez à votre entreprise la plateforme numérique qu'elle mérite, où innovation et excellence se rencontrent</a>
          </div>


          <div className="WebSPhotoWrapper">
            
          <img className="WebP1" src="/Images/WebOver.png" />
          <img className="WebP2" src="/Images/WebUnder.png" />
          </div>

          

        </div>




        <div className="WeblS2">
          <div className="Web1 WebDisplay"><img className="WebI1" src="/Images/WebI1.png" /> <b className="TitreSubWeb">Web design</b>
          <a className="InfoDisplayT">
-Conception de votre logo pour une identité visuelle distinctive.<br/><br/>
-Création de maquettes de conception de site web personnalisées.<br/><br/>
-Sélection et intégration de palettes de couleurs harmonieuses.<br/><br/>
-Choix de polices de caractères adaptées pour une lisibilité optimale.<br/><br/>
-Mise en page responsive pour une adaptation fluide sur tous les appareils.<br/><br/>
-Intégration de médias riches tels que des images, des vidéos et des animations.<br/><br/>
-Utilisation de techniques de design UX/UI pour améliorer l'ergonomie et l'expérience utilisateur.</a></div>
          <div className="Web2 WebDisplay2"><img className="WebI2" src="/Images/WebI2.png" /> <b className="TitreSubWeb"> Web developpement </b>
          <a className="WebDisplayT" >
-Transformation de vos idées en maquettes de site web fonctionnelles.<br/><br/>
-Création de sites web sur mesure pour représenter votre entreprise en ligne.<br/><br/>
-Intégration de fonctionnalités personnalisées pour répondre à vos besoins spécifiques.<br/><br/>
-Optimisation pour assurer une expérience utilisateur fluide et intuitive.<br/><br/>
-Tests rigoureux pour garantir la qualité et la fiabilité de votre site.<br/><br/>
-Formation et support pour vous accompagner dans la gestion de votre site après son lancement.<br/><br/>
-Collaboration étroite pour s'assurer que votre vision se concrétise parfaitement en ligne.</a></div>
          <div className="Web3 WebDisplay3"><img className="WebI3" src="/Images/WebI3.png" /> <b className="TitreSubWeb">Hébergement</b>
          <a className="WebDisplayT">

-Hébergement fiable et sécurisé pour votre site web.<br/><br/>
-Enregistrement et gestion de votre nom de domaine.<br/><br/>
-Garantie d'une disponibilité optimale pour que votre site soit toujours accessible.<br/><br/>
-Performances élevées pour des temps de chargement rapides.<br/><br/>
-Support technique réactif pour répondre à vos besoins rapidement.
</a></div>



        </div>


        

        
       
      </div>
    );
  }
}
