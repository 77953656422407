import React, { Component } from "react";
import "./header.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      menuOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 0 && !this.state.scrolled) {
      this.setState({ scrolled: true });
    } else if (window.scrollY === 0 && this.state.scrolled) {
      this.setState({ scrolled: false });
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  render() {
    return (
      <div className={`header ${this.state.scrolled ? "scrolled" : ""}`}>
        <div className="inner_header">
          <div className="logo_container">
            <a href="/">
              <img
                className="logo"
                src="/Images/Logo.png"
                alt="Logo"
                style={{ width: "250px", height: "auto" }}
              />
            </a>
          </div>
          <div className="menu_toggle" onClick={this.toggleMenu}>
            <div className="menu_icon"></div>
            <div className="menu_icon"></div>
            <div className="menu_icon"></div>
          </div>
          <ul className={`navigation ${this.state.menuOpen ? "open" : ""}`}>
            <li>
              <a href="/">Accueil</a>
            </li>
            <li>
              <a className="main-link">Nos prestations</a>
              <div className="dropdown">
                <div className="dropdown-content">
                  <a href="/Fixe">Télephonie fixe</a>
                  <a href="/Mobile">Télephonie mobile</a>
                  <a href="/PageInternet">Internet</a>
                  <a href="/Imprimante">Solutions d'impression</a>
                  <a href="/Cameras">Caméra de surveillance</a>
                  <a href="/PageAffichage">Affichage Dynamique</a>
                  <a href="/Informatique">Informatique</a>
                  <a href="/Web">Site Web</a>
                </div>
              </div>
            </li>
            <li>
              <a href="/Contact">Contact</a>
            </li>
            <li>
              <a href="/NousPresenter">Qui sommes nous?</a>
            </li>
            <li>
              <a className="Devis" href="/FormulaireEtude">
                LANCER VOTRE ETUDE
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
