import React, { Component } from "react";
import "./formulaireEtude.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import ReactGA from "react-ga4"; // Ensure you're using react-ga4

emailjs.init("NLS1RZNJAaIwSjWMK");
ReactGA.initialize("G-NJ0F6YH0PD"); // Use your Measurement ID

export default class FormulaireEtude extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOperatorTextField: false,
      showServerTextField: false,
    };
    this.formRef = React.createRef();
    this.recaptchaRef = React.createRef();
    this.sendEmail = this.sendEmail.bind(this);
    this.handleOperatorToggle = this.handleOperatorToggle.bind(this);
    this.handleServerToggle = this.handleServerToggle.bind(this);
    this.trackScrollDepth = this.trackScrollDepth.bind(this);
    this.handleFieldClick = this.handleFieldClick.bind(this);
  }

  componentDidMount() {
    // Track page view
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });

    // Track scroll depth
    window.addEventListener("scroll", this.trackScrollDepth);

    // Add click event listeners for form fields
    const fields = document.querySelectorAll(".formulaire-etude .field input");
    fields.forEach((field) => {
      field.addEventListener("click", () => this.handleFieldClick(field.id));
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.trackScrollDepth);
  }

  trackScrollDepth() {
    const scrollDepth = Math.round(
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
        100
    );
    ReactGA.event({
      category: "Scroll",
      action: "Scroll Depth",
      label: window.location.pathname,
      value: scrollDepth,
    });
  }

  handleFieldClick(fieldId) {
    ReactGA.event({
      category: "Form",
      action: "Click Field",
      label: fieldId,
    });
  }

  sendEmail(e) {
    e.preventDefault();
    const recaptchaValue = this.recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      alert("Veuillez compléter la vérification ReCAPTCHA !!!");
      return;
    }

    emailjs
      .sendForm("service_kf872hn", "template_m6nfhxr", this.formRef.current)
      .then(
        () => {
          console.log("Votre message a été pris en compte !!!");
          alert("Envoyé avec succès !");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Erreur");
        }
      );
  }

  handleOperatorToggle() {
    this.setState((prevState) => ({
      showOperatorTextField: !prevState.showOperatorTextField,
    }));
  }

  handleServerToggle() {
    this.setState((prevState) => ({
      showServerTextField: !prevState.showServerTextField,
    }));
  }

  render() {
    const { showOperatorTextField, showServerTextField } = this.state;

    return (
      <div className="FormulaireWrapper">
        <div className="Image-FW"> </div>
        <div className="Image-FW2"> </div>

        <form
          ref={this.formRef}
          className="formulaire-etude"
          onSubmit={this.sendEmail}
        >
          <div className="field">
            <label htmlFor="nom">Nom/Prenom:</label>
            <input type="text" id="nom" name="nom" />
          </div>
          <div className="field">
            <label htmlFor="fonction">Fonction:</label>
            <input type="text" id="fonction" name="fonction" />
          </div>
          <div className="field">
            <label htmlFor="adresse">Adresse:</label>
            <input type="text" id="adresse" name="adresse" />
          </div>
          <div className="field">
            <label htmlFor="mail">Mail:</label>
            <input type="email" id="mail" name="mail" />
          </div>
          <div className="field">
            <label htmlFor="numero-telephone">Numero téléphone:</label>
            <input type="tel" id="numero-telephone" name="numero-telephone" />
          </div>
          <div className="field">
            <label htmlFor="nombre-postes-telephoniques">
              Nombre de Postes téléphoniques:
            </label>
            <input
              type="number"
              id="nombre-postes-telephoniques"
              name="nombre-postes-telephoniques"
            />
          </div>
          <div className="field">
            <label htmlFor="nombre-lignes-mobiles">
              Nombre de lignes mobiles:
            </label>
            <input
              type="number"
              id="nombre-lignes-mobiles"
              name="nombre-lignes-mobiles"
            />
          </div>
          <div className="field">
            <label htmlFor="operateur-telecom">Opérateur Telecom:</label>
            <input
              type="text"
              id="operateur-telecom"
              name="operateur-telecom"
              className="operator-text-field"
              style={{ display: showOperatorTextField ? "block" : "none" }}
            />
            <label className="switch">
              <input
                type="checkbox"
                id="operateur-telecom-switch"
                name="operateur-telecom-switch"
                onChange={this.handleOperatorToggle}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="field">
            <label htmlFor="serveur-informatique">Serveur informatique:</label>
            <input
              type="text"
              id="serveur-informatique"
              name="serveur-informatique"
              className="server-text-field"
              style={{ display: showServerTextField ? "block" : "none" }}
            />
            <label className="switch">
              <input
                type="checkbox"
                id="serveur-informatique-switch"
                name="serveur-informatique-switch"
                onChange={this.handleServerToggle}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey="6Lcw7bgpAAAAAA4ahfp3LMIrtO4Caz3EhdqBMAmT"
          />
          <button type="submit" className="submit-btn">
            Envoyer
          </button>
        </form>
      </div>
    );
  }
}
