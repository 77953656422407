import React, { Component } from "react";
import "./imprimante.css";
import ReactGA from 'react-ga';

ReactGA.initialize('G-NJ0F6YH0PD');

export default class PageImprimante extends Component {
  componentDidMount() {
    // Track page view O-O
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Track scroll depth xD
    window.addEventListener('scroll', this.trackScrollDepth);

    // Add click event listeners for buttons (too many to count)
    const buttons = document.querySelectorAll('button');
    buttons.forEach(button => {
      button.addEventListener('click', () => this.handleButtonClick(button.innerText));
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackScrollDepth);

    
    const buttons = document.querySelectorAll('button');
    buttons.forEach(button => {
      button.removeEventListener('click', this.handleButtonClick);
    });
  }

  trackScrollDepth = () => {
    const scrollDepth = Math.round((window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100);
    ReactGA.event({
      category: 'Scroll',
      action: 'Scroll Depth',
      label: window.location.pathname,
      value: scrollDepth
    });
  };

  handleButtonClick = (buttonText) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click Button',
      label: buttonText
    });
  };

  render() {
    return (
      <div className="ImprimPWrapper">


        <div className="ImprimS1">

          <div className="ImprimSTexte">
            <a className="ImprimTitre">Les avantages de notre solutions d’impression avec WINTECH</a><br/><br/>
            <a className="ImprimeTexte">L’impression est un aspect essentiel pour une entreprise, les coûts d’impression représentent près de 5% du Chiffre d’affaire global d’une entreprise.<br/><br/>
                                        En réalisant un audit de votre infrastructure d’impression, vous pouvez réduire vos coûts d’impression jusqu’à 30% .</a>
          </div>

          <div className="ImprimeSPhotoWrapper">
          <img className="ImprimentP1" src="/Images/ImprimOver.png" />
            <img  className="ImprimentP2" src="/Images/ImprimUnder.png"/>
          </div>

        </div>




        <div className="ImprilS2">
          <div className="Imprimante1 ImprimDisplay"><img className="ImprimDisplayP" src="/Images/Canon1.png"/> <a className="ImprimDisplayT"><b>Marque :</b> Canon<br/>
          <b>Référence : </b>IMP/CAN/TM240-MFP<br/>
          <b>Type : </b>MFP<br/>
          <b>Format standard : </b>A1, A2, A3, A4, 24"<br/>
          <b>Chromie : </b>5 couleurs<br/>
          <b>Technologie : </b>Capteur unique pour des numérisations</a></div>
          <div className="Imprimante2 ImprimDisplay"><img className="ImprimDisplayP" src="/Images/Canon2.png"/> <a className="ImprimDisplayT" ><b>Marque : </b>Canon<br/>
          <b>Référence : </b>i-Sensys MF443dw<br/>
          <b>Type :</b> MFP<br/>
          <b>Format standard : </b>A4<br/>
          <b>Chromie : </b>monochrome<br/>
          <b>Technologie : </b>Laser</a></div>
          <div className="Imprimante3 ImprimDisplay"><img className="ImprimDisplayP" src="/Images/Canon3.png"/> <a className="ImprimDisplayT"><b>Marque : </b> Canon<br/>
          <b>Référence :</b> iR Advance C3520i<br/>
          <b>Type : </b>MFP<br/>
          <b>Format standard : </b>A3<br/>
          <b>Chromie : </b>couleur<br/>
          <b>Technologie :</b> Laser</a></div>
        </div>


        <img  className="ImprimanteBG" src="/Images/Ellipse15.png"/>


        
       
      </div>
    );
  }
}
