import React, { Component } from "react";
import "./pageTelFixe.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faServer, faPerson } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";

ReactGA.initialize("G-NJ0F6YH0PD");

export default class PageTelFix extends Component {
  componentDidMount() {
    // Track page view
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Track scroll depth
    window.addEventListener("scroll", this.trackScrollDepth);

    // Add click event listeners for form fields
    const fields = document.querySelectorAll(".field input");
    fields.forEach((field) => {
      field.addEventListener("click", () => this.handleFieldClick(field.id));
    });

    // Add click event listeners for buttons
    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => {
      button.addEventListener("click", () =>
        this.handleButtonClick(button.innerText)
      );
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.trackScrollDepth);

    // Clean up event listeners for fields and buttons
    const fields = document.querySelectorAll(".field input");
    fields.forEach((field) => {
      field.removeEventListener("click", this.handleFieldClick);
    });

    const buttons = document.querySelectorAll("button");
    buttons.forEach((button) => {
      button.removeEventListener("click", this.handleButtonClick);
    });
  }

  trackScrollDepth = () => {
    const scrollDepth = Math.round(
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
        100
    );
    ReactGA.event({
      category: "Scroll",
      action: "Scroll Depth",
      label: window.location.pathname,
      value: scrollDepth,
    });
  };

  handleFieldClick = (fieldId) => {
    ReactGA.event({
      category: "Form",
      action: "Click Field",
      label: fieldId,
    });
  };

  handleButtonClick = (buttonText) => {
    ReactGA.event({
      category: "Button",
      action: "Click Button",
      label: buttonText,
    });
  };

  render() {
    return (
      <div className="FPWrapper">
        <div className="FS1">
          <div className="FSTexte">
            <a className="FTitre">
              {" "}
              Pourquoi choisir notre réseau téléphonique ?
            </a>
            <br />
            <br />
            <a className="FTexte">
              WINTECH vous offre la téléphonie haute définition, souple et
              adaptée à votre organisation. Profitez d’une technologie avancée
              sans installation de matériels supplémentaires, sans compression
              de voix, garantissant la sécurité
              <br />
              <br />
              <br />
              <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
              &ensp;Personnalisation des fonctionnalités via la téléphonie IP
              d’entreprise
              <br />
              <br />
              <FontAwesomeIcon icon={faServer}></FontAwesomeIcon>
              &ensp;Performance et couverture de la téléphonie
              <br />
              <br />
              <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>&ensp;&ensp;Des
              équipes support et clients basées en France
              <br />
              <br />
            </a>
          </div>

          <div className="FPhotoWrapper">
            <img className="FP1" src="/Images/FixOver.png" />
            <img className="FP2" src="/Images/FixUnder.png" />
          </div>
        </div>

        <div className="FTitreMilieu">
          <a>NOS SOLUTIONS TÉLÉPHONIQUES POUR VOTRE ENTREPRISE</a>
        </div>

        <div className="FixSectionMillieu">
          <div className="FixTitre"></div>
          <div className="FIxOffres">
            <div className="FixOffre1">
              <img className="S1" src="/Images/S1.png" />
              <b>Serveur vocal intéractif</b>
              <a>
                Maitrise des réseaux
                <br />
                <br />
                <br />
                Son haute définition
                <br />
                <br />
                <br />
                Appels illimités
                <br />
                <br />
                <br />
                Appels simultané
              </a>
            </div>
            <div className="FixOffre2">
              <img className="S2" src="/Images/S2.png" />
              <b>Téléphonie IP</b>
              <a>
                Fils d’attente
                <br />
                <br />
                <br />
                Routage
                <br />
                <br />
                <br />
                Accueil multisite
                <br />
                <br />
                <br />
                Ligne de secours
              </a>
            </div>
          </div>
        </div>

        <div className="FlS2">
          <div className="Imprimante1 FDisplay">
            <img className="FDisplayP" src="/Images/F1.png" />
            <a className="FDisplayT">
              <b>Marque :</b> Yealink
              <br />
              <b>Référence : </b>SIP-T54W
              <br />
              <b>Type : </b>Téléphone IP
              <br />
              <b>Type de combiné : </b> filaire
              <br />
              <b>Ethernet/LAN : </b>OUI
              <br />
              <b>Wifi : </b>OUI
              <br />
              <b className="Bloototh">Bluetooth : </b>OUI
            </a>
          </div>
          <div className="Imprimante2 FDisplay">
            <img className="FDisplayP2" src="/Images/F2.png" />
            <a className="FDisplayT">
              <b>Marque : </b>Yealink
              <br />
              <b>Référence : </b> SIP-T57W
              <br />
              <b>Type : </b>Téléphone IP
              <br />
              <b>Type de combiné : </b> filaire
              <br />
              <b>Ethernet/LAN : </b>OUI
              <br />
              <b>Wifi : </b>OUI
              <br />
              <b className="Bloototh">Bluetooth : </b>OUI
            </a>
          </div>
          <div className="Imprimante3 FDisplay">
            <img className="FDisplayP" src="/Images/F3.png" />
            <a className="FDisplayT">
              <b>Marque : </b>Yealink
              <br />
              <b>Référence :</b> W73P
              <br />
              <b>Type : </b>Téléphone IP
              <br />
              <b className="Bloototh">Type de combiné : </b>sans fil
            </a>
          </div>
        </div>

        <img className="FBG" src="/Images/Ellipse15.png" />
        <img className="FBG2" src="/Images/Ellipse16.png" />
        <img className="FBG3" src="/Images/Ellipse17.png" />
        <img className="FBG4" src="/Images/Ellipse18.png" />
      </div>
    );
  }
}
